window.projectsSliderFeatureHandler = () => {
    document.addEventListener('DOMContentLoaded', () => {
        sliderEvents();
        showSlider();
    });

    const sliderEvents = () => {
        const sliders = document.querySelectorAll('.jsSlickProjectsSliderFeature');

        sliders?.forEach(slider => {
            $(slider).slick({
                arrows: true,
                dots: false,
                gap: 50,
                infinite: false,
                lazyLoad: 'ondemand',
                slidesToScroll: 5,
                slidesToShow: 5,
                speed: 500,
                responsive: [
                    {
                        breakpoint: 2560,
                        settings: {
                            slidesToScroll: 3,
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: gridBreakpoints.xl,
                        settings: {
                            slidesToScroll: 2,
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: gridBreakpoints.md,
                        settings: {
                            draggable: true,
                            slidesToScroll: 1,
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        });
    };

    const showSlider = () => {
        const modules = document.querySelectorAll('.jsProjectsSliderFeature');

        modules?.forEach(module => {
            module.classList.add('visible');
        });
    };
};
